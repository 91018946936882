@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Noto+Sans+JP:wght@100&display=swap");
body {
  margin: 0;
  font-family: "Gloria Hallelujah", cursive !important;
  font-family: "Noto Sans JP", sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(34, 20, 41), rgb(34, 15, 35));
  
}
